<script setup lang="ts">
import type { NuxtLinkProps } from '#app'
import type { Color } from '~/components/BaseForm/types'

type AdminLink = {
  iconName: IconPath
  methods?: 'send-comment' | 'download-pdf'
  text: string
  textPhone: string
  toBind?: NuxtLinkProps
}

const props = withDefaults(
  defineProps<{
    color?: Color
    houseId: number
    privateToken?: string
    slug: string
  }>(),
  {
    color: 'grey',
    privateToken: '',
  },
)
const emits = defineEmits<{
  'handle-download-pdf': [AdminLink]
  'handle-send-comment': [AdminLink]
}>()

const { locale, t } = useI18n()
const localePath = useLocalePath()

const linkDescription = ref('')
const housePath = computed(() => {
  const query: {
    'private-token'?: string
  } = {}

  if (props.privateToken) {
    query['private-token'] = props.privateToken
  }

  return localePath({
    name: 'house-slug',
    params: { slug: props.slug },
    query,
  })
})

const adminLinks = computed<AdminLink[]>(() => [
  {
    iconName: 'home',
    text: t('global.see_page'),
    textPhone: t('global.website'),
    toBind: {
      target: '_blank',
      to: housePath.value,
    },
  },
  {
    iconName: 'settings',
    text: t('global.houseInfo'),
    textPhone: 'AdminTool',
    toBind: {
      external: true,
      href: `/api/${locale.value}/admintool/${props.houseId}`,
      target: '_blank',
    },
  },
  {
    iconName: 'messageText',
    text: t('global.makeRequest'),
    textPhone: t('global.request'),
    methods: 'send-comment',
  },
  {
    iconName: 'page',
    text: t('houseDownloadPdf.downloadPdf'),
    textPhone: 'PDF',
    methods: 'download-pdf',
  },
])
const clickedLink = (e: Event, link: AdminLink) => {
  if (link.methods) {
    e.stopPropagation()
    if (link.methods === 'download-pdf') emits('handle-download-pdf', link)
    else if (link.methods === 'send-comment') emits('handle-send-comment', link)
  }
}
const toggleLinkDescription = (wording = '') => {
  linkDescription.value = wording
}
</script>

<template>
  <div class="flex flex-col justify-center">
    <ul
      class="admin-house-links-container mb-0 flex w-full justify-around px-3 md:justify-center"
    >
      <li v-for="link in adminLinks" :key="link.text">
        <BaseButton
          :aria-label="link.text"
          :color="color as Color"
          class="admin-house-link"
          v-bind="link.toBind"
          variant="link"
          @click="clickedLink($event, link)"
          @mouseleave="toggleLinkDescription()"
          @mouseover="toggleLinkDescription(link.text)"
        >
          <span
            class="flex size-[44px] items-center justify-center rounded-full border border-gray-400"
          >
            <BaseIcon :name="link.iconName" />
          </span>
          <span class="mt-1 text-sm font-normal text-gray-400 md:hidden">
            {{ link.textPhone }}
          </span>
        </BaseButton>
      </li>
    </ul>
    <div class="absolute top-3/4 -translate-y-1/2 text-white">
      {{ linkDescription }}
    </div>
  </div>
</template>

<style scoped>
.admin-house-link {
  @apply flex flex-col items-center justify-center md:mx-2;
}

.admin-house-links-container button:hover,
.admin-house-links-container a:hover,
.admin-house-links-container button:focus,
.admin-house-links-container a:focus {
  @apply no-underline;
}

.admin-house-links-container button:hover,
.admin-house-links-container a:hover {
  @apply text-primary-800;
}

.admin-house-links-container button:hover span,
.admin-house-links-container a:hover span {
  @apply bg-white;
}
</style>
