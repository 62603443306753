<script setup lang="ts">
import { useButtons } from '~/components/BaseForm/compose/use-buttons'
import groupFields from '~/forms/search/request/requestForm'
import type { MutationType } from '~/components/BaseForm/types'
import type { SendMutationType } from '~/helpers/BaseForm/sendMutation'

const props = defineProps<{
  houseId: number
}>()
const emits = defineEmits<{
  canceled: []
}>()

const { $api } = useNuxtApp()
const { t } = useI18n()

const { btnPrimary, btnSecondary } = useButtons()
btnPrimary.text = computed(() => t('houseRequest.submitRequest'))
if (btnPrimary.attrs) btnPrimary.attrs.class = 'order-1 mb-4 md:mb-0 md:order-2'
btnSecondary.text = computed(() => t('account.cancel'))
if (btnSecondary.attrs) btnSecondary.attrs.class = 'order-2 md:mr-6 md:order-1'

const success = ref(false)
const initialData = reactive({
  subject: null,
  notes: '',
})
const groupFieldsForm = reactive(groupFields())

const disabledButton = computed(
  () => !initialData.subject || !initialData.notes || success.value,
)

const createRequest = (data: typeof initialData) =>
  $api.v2.adminChangeRequests.create(data)

const mutation: MutationType<typeof initialData> = (data) => ({
  request: createRequest,
  input: {
    change_request: {
      house_id: props.houseId,
      notes: data.notes,
      subject: data.subject,
    },
  },
})

const onSubmit: () => SendMutationType['onSubmit'] = () => ({
  success: () => {
    success.value = true
    setTimeout(() => {
      emits('canceled')
    }, 2500)
  },
  error: (_) => {},
})

const onCancel = () => {
  emits('canceled')
}
</script>

<template>
  <div>
    <h2 class="text-4xl font-bold leading-8">
      {{ $t('houseRequest.title') }}
    </h2>

    <div class="mt-8">
      <BaseForm
        ref="adminHouseRequest"
        :active-error-scroll-to="false"
        :active-modal-errors="false"
        :active-modal-save-or-quit="false"
        :button-primary="btnPrimary"
        :button-secondary="btnSecondary"
        :disabled-primary-button="disabledButton"
        :group-fields="groupFieldsForm"
        :initial-data="initialData"
        edition-mode
        id-form="admin-house-request-form"
        :mutation="mutation"
        :on-submit="onSubmit"
        @on-cancel="onCancel"
      >
        <template #before-container-button>
          <div class="my-4 h-6 text-center md:mb-6">
            <p
              v-if="success"
              class="flex justify-center text-base text-gray-700"
            >
              {{ $t('houseRequest.submittedRequest') }}
              <BaseIcon class="icon-overlay ml-3 text-success" name="check" />
            </p>
          </div>
        </template>
      </BaseForm>
    </div>
  </div>
</template>
