<script setup lang="ts">
const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    clientFeesRate: string
    commissionRate: number
    commissionRatePublicPrice: number
    conciergeServiceOffer: 'essential' | 'tailor' | 'club'
    definitivePrices?: boolean
    hasOperationalCosts: boolean
    lcCommission?: string
    netOwnerCommissionRate: number
    operationalCosts?: string
    ownerPriceWithoutOpCosts?: string
    periodValid: boolean
    price?: string
    priceValue?: number | null
  }>(),
  {
    definitivePrices: true,
    lcCommission: '',
    operationalCosts: '',
    ownerPriceWithoutOpCosts: '',
    price: '',
    priceValue: null,
  },
)

const houseDetailsList = computed(() => {
  let houseDetailsList

  if (props.periodValid) {
    houseDetailsList = [
      {
        info: props.ownerPriceWithoutOpCosts,
        text: t('search.netOwnerPrice'),
      },
      {
        info: props.operationalCosts,
        text: t('search.operationalCosts'),
      },
      {
        info: props.lcCommission,
        text: t('search.lcCommission'),
      },
      {
        info: props.clientFeesRate,
        text: t('search.clientFees'),
      },
    ]
  } else {
    houseDetailsList = [
      {
        info: props.hasOperationalCosts ? t('global.yes') : t('global.no'),
        text: t('search.operationalCosts'),
      },
      {
        info: `${props.commissionRatePublicPrice}%`,
        text: t('search.publicPriceCommissionRate'),
      },
      {
        info: `${props.commissionRate}%`,
        text: t('search.netOwnerCommissionRate'),
      },
      {
        info: props.clientFeesRate,
        text: t('search.clientFees'),
      },
    ]
  }

  return houseDetailsList
})
</script>

<template>
  <div>
    <div class="mb-4 flex gap-2">
      <span v-if="price" class="text-md font-bold">
        {{ price }}
      </span>
      <span
        v-if="priceValue && !definitivePrices"
        class="flex items-center rounded-full bg-error/20 px-2 py-1 text-3xs text-error"
      >
        <BaseIcon name="warningTriangle" :size="1.1" class="mr-1 font-bold" />
        {{ $t('house.priceNotValidated') }}
      </span>
    </div>

    <ul class="m-0 list-disc pl-4 text-md text-gray-600">
      <li v-for="item in houseDetailsList" :key="item.text" class="mb-2">
        {{ item.text }} <span class="font-bold">{{ item.info }}</span>
      </li>
    </ul>

    <div
      class="mt-3 flex items-center border-t border-gray-200 pt-3 text-md font-bold"
    >
      {{ $t(`house.conciergerieOffer.${conciergeServiceOffer}`) }}
      <HouseConciergeRankOffer
        :rank="conciergeServiceOffer"
        class="ml-2"
        :size="0.7"
      />
    </div>
  </div>
</template>
