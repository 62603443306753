import { $dayjs } from '@/utils/dayjs'
import { transformPrice, getPriceWithCurrency } from '~/helpers/priceHelper'

import type {
  StateSearchResults,
  SearchAdminHouse,
  SalesOption,
} from '~/types/search/types'
import type { Locale } from 'lc-services/types'

export const useSearchAdminResults = ({
  hasDates,
}: {
  hasDates: Ref<boolean>
}) => {
  const stateSearchResults = useState<StateSearchResults>(
    'state-search-results',
  )
  const { t, locale } = useI18n<unknown, Locale>()
  const { currency } = useUser()

  const arroundNetOwnerCommissionRate = (commissionRate: number | null) => {
    if (commissionRate) {
      return Number.isInteger(commissionRate)
        ? commissionRate
        : Number(commissionRate.toFixed(1))
    }

    return 0
  }

  const formattedHits = computed<SearchAdminHouse[]>(() => {
    return stateSearchResults.value.hits.map((hit) => {
      const isValidPeriod =
        (hit?.fullyCovered && hit?.allMinimumDurationValid) || false

      const currentPrice = transformPrice({
        currency: currency.value,
        hasDates: hasDates.value,
        hit,
        i18n: {
          locale,
          t,
        },
        isValidPeriod,
        userIsAdmin: true,
        userIsPartner: false,
      })
      const lastUpdated = (date: string | null) => {
        if (!date) return ''
        return t('search.lastUpdated', {
          date: $dayjs(date).format('DD MMMM YYYY'),
          time: $dayjs(date).format('hh:mm'),
        })
      }

      const formatDateSalesOptions = (salesOptions: SalesOption | null) => {
        if (!salesOptions || !salesOptions.length) return null
        return {
          checkInDate: $dayjs(salesOptions[0], 'YYYY-MM-DD').format(
            'MMM D, YYYY',
          ),
          checkOutDate: $dayjs(salesOptions[1], 'YYYY-MM-DD').format(
            'MMM D, YYYY',
          ),
        }
      }

      const lcCommission = isValidPeriod
        ? getPriceWithCurrency({
            price: Number(hit.lcCommission),
            currency: currency.value,
            i18n: { locale },
          })
        : undefined
      const operationalCosts = isValidPeriod
        ? getPriceWithCurrency({
            price: Number(hit.operationalCosts),
            currency: currency.value,
            i18n: { locale },
          })
        : undefined
      const ownerPriceWithoutOpCosts = isValidPeriod
        ? getPriceWithCurrency({
            price: Number(hit.ownerPriceWithoutOpCosts),
            currency: currency.value,
            i18n: { locale },
          })
        : undefined
      return {
        availabilitiesUpdatedAt: lastUpdated(hit.availabilities_updated_at),
        bathrooms: hit.bathrooms,
        bedrooms: hit.bedrooms,
        capacity: hit.capacity,
        clientFeesRate: `${hit.client_fees_rate}%`,
        commissionRate: Number(hit.commission_rate),
        commissionRatePublicPrice: Number(hit.commission_rate_public_price),
        conciergeServiceOffer: hit.concierge_service_offer,
        currentPrice,
        definitivePrices: hit.definitivePrices,
        destination: hit.destination[locale.value],
        destinationId: Number(hit.destination_id),
        displayPrices: hit.display_prices,
        exclusivity: hit.exclusivity,
        firstPhotoUrl: hit.first_photo_url,
        hasOperationalCosts: Boolean(hit.operational_costs),
        hasSalesOptions: Boolean(hit.currentSalesOptionDates),
        iconicCollection: hit.iconic_collection,
        id: hit.id,
        lcCommission,
        netOwnerCommissionRate: arroundNetOwnerCommissionRate(
          Number(hit.commission_rate),
        ),
        name: hit.name,
        operationalCosts,
        ownerPriceWithoutOpCosts,
        periodValid: isValidPeriod,
        photos: hit.photos,
        privateToken: hit.private_token ?? '',
        slug: hit.slug[locale.value],
        state: hit.state,
        warning: hit.warning,
        warningReason: hit.warning_reason,
        currentSalesOptionDates: formatDateSalesOptions(
          hit.currentSalesOptionDates,
        ),
        currentSalesOptionOwner: hit.currentSalesOptionOwner,
        currentHubspotContracts: Object.keys(hit.currentHubspotContracts ?? {})
          .length
          ? hit.currentHubspotContracts
          : null,
      }
    })
  })

  return {
    formattedHits,
  }
}
