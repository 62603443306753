<script setup lang="ts">
import { $dayjs } from '@/utils/dayjs'

import { getDestinations } from '~/helpers/search/getDestinations'

import type { Locale } from 'lc-services/types'
import type {
  InputSearchQuery,
  NumericFilter,
  QueryModelType,
  RefetchResultsKeys,
  ReplicasValues,
  SearchFacetObject,
  SearchFacetValue,
  SearchPartnerHouse,
} from '~/types/search/types'
import type { StateDestination } from '~/types/types'

const { isSmallScreen, isBigScreen, isDesktopOrTablet } = useBreakpoint()
const { t, locale } = useI18n()
const { $api, $lcRepositories } = useNuxtApp()

$dayjs.locale(locale.value)

const stateDestination = useState<StateDestination>('destination')
const destination = stateDestination.value?.destination || {}
const disableQuery = stateDestination.value?.disableQuery || false
const isIndexesFilter = stateDestination.value?.isIndexesFilter || false
let destinationId = stateDestination.value?.destinationId || null
const queryOutput = stateDestination.value?.queryOutput || {}

const localePath = useLocalePath()
const route = useRoute()
const router = useRouter()
const { headerNavigation } = useHeaderNavigation()
const { setFiltersCount } = useSearchFiltersCount()
const { currency } = useUser()

const showModalProposalCreate = ref(false)
const isLoading = ref(false)
const mapVisible = ref(false)
const searchContainer = ref<HTMLElement | null>(null)
const showFiltersModal = ref(false)

const {
  bathrooms,
  bedrooms,
  capacity,
  setNumericFilter,
  clearAllNumericFilters,
  clearNumericFilterByType,
} = useSearchNumericFilters()

const {
  newFacetFilters,
  facetsFiltersParams,
  facetFilters,
  clearAllFacetFilters,
  setFacetFilter,
} = useSearchFacetFilters()

const {
  clearDateFilters,
  setDateStart,
  setDateEnd,
  startDate,
  endDate,
  hasDates,
} = useSearchDateFilters()

const { minBudget, maxBudget, price, clearBudgetFilters, setBudgetFilters } =
  useSearchBudgetFilters()

const { currentReplica, replicas, setReplicaFilters } =
  useSearchReplicaFilters()

const {
  queryValueMultiple,
  apiParamsDestinationIds,
  fetchParentLocation,
  clearMultipleLocationsFilters,
  setMultipleLocations,
  queryDestinationIds,
  queryHouseIds,
} = useSearchLocationFilters()

const {
  facets,
  fetchHouseMapResults,
  fetchHouseResults,
  hasOnlyInvalidResults,
  hasResults,
  hitsMap,
  housesPerPage,
  initClientSide,
  invalidHouses,
  keepOldResults,
  nbHits,
  nbPages,
  page,
  resetRefetchProperties,
  setKeepOldResults,
  setPage,
  showPreviousButton,
} = useSearchResults({ startDate, endDate })

const { formattedHits } = useSearchPartnerResults({ hasDates })

const { currentHouseFavoriteSelected, setCurrentHouseFavorite } =
  useSearchWishlist()
const { showMapButton } = useSearchMap(searchContainer, mapVisible)
const { trackSearchHouse, trackSearchResultsViewed } = useSearchTracking()
const { updateRouter } = useSearchUpdateRouter()

const searchHousesPayload = computed(() => {
  const payload: QueryModelType = {
    initializelist: !keepOldResults.value || !initClientSide.value,
    mode: 'b2b2c',
    bathrooms: bathrooms.value ? bathrooms.value.toString() : '1',
    bedrooms: bedrooms.value ? bedrooms.value.toString() : '1',
    capacity: capacity.value ? capacity.value.toString() : '1',
    itemsPerPage: housesPerPage.value,
    replica: currentReplica.value.label,
  }

  if (apiParamsDestinationIds.value.length) {
    payload.destinationIds = apiParamsDestinationIds.value
  }
  if (page.value > 0) {
    payload.page = page.value
  }
  if (newFacetFilters.value?.size) {
    payload.facetFilters = newFacetFilters.value
  }
  if (price.value.length) {
    payload.prices = price.value
  }
  if (startDate.value && endDate.value) {
    payload.period = {
      startDate: startDate.value,
      endDate: endDate.value,
    }
  }
  if (queryHouseIds.value.length) {
    payload.housesIds = queryHouseIds.value
  }

  return payload
})

const searchDestination = computed(
  () => headerNavigation.value.searchDestination,
)
const showPaginate = computed(() => nbPages.value > page.value + 1)
const numberPlaceholderProducts = computed(() =>
  isDesktopOrTablet.value ? 6 : 2,
)

const searchResultsText = computed(() => {
  if (
    mapVisible.value &&
    hitsMap.value.length > 0 &&
    startDate.value &&
    endDate.value
  ) {
    return t('search.results', hitsMap.value.length)
  }

  return t('search.results', nbHits.value)
})

const { data: searchableTagsIdentifiers } = await useAsyncData(
  'search-admin-async-data',
  async () => {
    useSearchInitStates({
      typeSearch: 'b2b2c',
      destination,
      userCurrency: currency.value,
      queryOutput,
    })

    if (import.meta.client) {
      setKeepOldResults(false)
      setPage(0)
    }

    await fetchHouseResults({
      currency,
      searchHousesPayload: searchHousesPayload.value,
    })

    //
    // prefill search Input
    let destinations: { name: string; id: number }[] = []
    if (queryDestinationIds.value.length) {
      const res = await getDestinations({
        $api,
        destinationId: queryDestinationIds.value,
        locale: locale.value as Locale,
      })

      destinations = res as { name: string; id: number }[]
    }

    const locationDestinations =
      destinations?.map<InputSearchQuery>((d) => ({
        value: d.id,
        label: d.name,
        type: 'location' as const,
      })) || []

    const locationHits = formattedHits.value
      .filter((f) => queryHouseIds.value.includes(String(f.id)))
      .map<InputSearchQuery>((h) => ({
        value: h.id,
        label: h.name,
        type: 'type-of-house' as const,
      }))

    const newLocations = [...locationDestinations, ...locationHits]
    if (destination.id) {
      const currentDestination: InputSearchQuery = {
        value: Number(destination.id),
        label: destination.name as string,
        type: 'location' as const,
      }

      newLocations.unshift(currentDestination)
    }

    setMultipleLocations(newLocations)
    // end prefill search Input
    //

    const res = await $lcRepositories.searchableTag.getIdentifiers()

    return (res?.errors ? [] : res) as typeof res.data
  },
)

// Watch
watch(
  () => currency.value,
  async (_) => {
    await refetchResultsFrom('currency')
  },
)

watch(
  () => showFiltersModal.value,
  (newValue) => {
    if (newValue) {
      window.scrollTo(0, 0)
    }
  },
)

// Watch when header search click on desti
watch(
  () => searchDestination.value,
  async (
    newValue: { name: { en: string; fr: string }; destinationId: number },
    _,
  ) => {
    if (newValue) {
      const fakeSelectedOption: InputSearchQuery = {
        label: newValue.name[locale.value as Locale],
        slug: '',
        type: 'location',
        value: newValue.destinationId,
      }

      await refineByQuery(fakeSelectedOption)
    }
  },
)

// Methods
const clearAllFilterModal = async () => {
  clearAllFacetFilters()
  clearAllNumericFilters()

  await refetchResultsFrom('clearAll')
}

const clearFilters = async () => {
  clearBudgetFilters()
  clearAllFacetFilters()
  clearAllNumericFilters()
  clearDateFilters()

  await refetchResultsFrom('clearAll')
}

const clearQuery = async () => {
  destinationId = ''
  clearMultipleLocationsFilters()

  await refetchResultsFrom('clearQuery')
}

const clearFilterNumeric = async (type: NumericFilter) => {
  clearNumericFilterByType(type)

  await refetchResultsFrom('clearFilterNumeric')
}

const toggleMap = async () => {
  window.scrollTo(0, 0)

  if (!mapVisible.value) {
    await refetchResultsFrom('map')

    mapVisible.value = true
  } else {
    mapVisible.value = false
  }
}

const filteredNumeric = async (
  attribute: NumericFilter,
  selectedFilter: number,
) => {
  setNumericFilter(attribute, selectedFilter)
  await refetchResultsFrom(`numericFilters-${attribute}`)
}

const paginate = async (
  pageDirection: 'next-page' | 'previous-page' = 'next-page',
) => {
  // we need to set previousPage and nextPage so that if multiple pages are already visible we don't duplicate content
  if (pageDirection === 'previous-page') {
    setPage(page.value - 1)
  } else {
    setPage(page.value + 1)
  }

  await refetchResultsFrom(pageDirection)

  window.scrollTo(0, window.scrollY)
}

const refineByQuery = async (selectedOption: InputSearchQuery) => {
  const newLocation: InputSearchQuery = {
    label: selectedOption.label,
    value: selectedOption.value,
    type: selectedOption.type,
  }

  setMultipleLocations([...queryValueMultiple.value, newLocation])

  await refetchResultsFrom('query')
}

const clearFacetsOther = async () => {
  clearAllFacetFilters()

  await refetchResultsFrom('facets')
}

const refineFacets = async (
  facet: SearchFacetObject<SearchFacetValue<string>[]>,
  facetValue: { checked: boolean; value: string },
) => {
  facetValue.checked = !facetValue.checked

  setFacetFilter(facet, facetValue)

  await refetchResultsFrom('facets')
}

const datesCleared = async () => {
  clearDateFilters()

  await refetchResultsFrom('dates')
}

const handleSetDatesStart = (date: Date) => {
  setDateStart(date)
}

const handleSetDatesEnd = (date: Date) => {
  setDateEnd(date)
  setDates()
}

const setDates = async () => {
  if (startDate.value && endDate.value) {
    await refetchResultsFrom('dates')
  }
}

const sortByReplica = async (replica: {
  label: ReplicasValues
  value: string
}) => {
  setReplicaFilters(replica)

  await refetchResultsFrom('replica')
}

const updateBudget = async (maxB: string, minB: string) => {
  setBudgetFilters(minB, maxB)

  await refetchResultsFrom('budget')
}

const setFavoriteHouse = (house: SearchPartnerHouse) => {
  if (house) {
    const price = house.currentPrice?.isValid
      ? house.currentPrice.value
      : undefined

    setCurrentHouseFavorite({
      id: house.id,
      name: house.name,
      iconicCollection: house.iconicCollection,
      publicPrice: price || undefined,
    })
  }
}

const triggerClick = () => {
  searchContainer.value?.click()
}

const trackHouse = (houseId: number) => {
  trackSearchHouse({
    houseId,
    mapVisible: mapVisible.value,
    searchableTagsIdentifiers: searchableTagsIdentifiers.value || [],
  })
}

const showHouseCardInfo = (houseId: number) => {
  const twoCardsPerLine =
    !isBigScreen.value && invalidHouses.value.first === houseId
  const threeCardsPerLine =
    isBigScreen.value && invalidHouses.value.second
      ? Number(invalidHouses.value.second) === Number(houseId)
      : Number(invalidHouses.value.first) === Number(houseId)

  return threeCardsPerLine || twoCardsPerLine
}

const toggleFiltersModal = () => {
  showFiltersModal.value = !showFiltersModal.value
}

onBeforeMount(() => {
  if (!showPreviousButton.value && page.value > 1) {
    const query = route.query

    delete query.page
    router.push({ query: { ...query } })
  }
})

const refetchResultsFrom = async (from: RefetchResultsKeys) => {
  resetRefetchProperties(from)

  isLoading.value = true

  if (from === 'map' || mapVisible.value) {
    await fetchHouseMapResults({
      currency,
      searchHousesPayload: searchHousesPayload.value,
    })
  } else {
    await fetchHouseResults({
      currency,
      searchHousesPayload: searchHousesPayload.value,
    })
  }

  isLoading.value = false

  // No result
  if (formattedHits.value.length === 0 && destinationId) {
    await fetchParentLocation(Number(destinationId))
    destinationId = null
    await refetchResultsFrom('getParentDestiHits')
  }

  trackSearchResultsViewed({
    mapVisible: mapVisible.value,
    searchableTagsIdentifiers: searchableTagsIdentifiers.value || [],
  })

  updateRouter(isIndexesFilter, disableQuery)
}

if (
  route?.name?.toString()?.includes('destination-slug-filter') &&
  nbHits.value === 0
) {
  const path = localePath({
    name: 'destination-name',
    params: { name: route.params.slug },
  })

  navigateTo(path, { redirectCode: 301 })
}

onMounted(() => {
  setFiltersCount(countTotalFiltersSelected.value)
  trackSearchResultsViewed({
    mapVisible: mapVisible.value,
    searchableTagsIdentifiers: searchableTagsIdentifiers.value || [],
  })
})

// Count selected filters
const countTotalFiltersSelected = computed(() => {
  let total = 0

  if (capacity.value) total += 1
  if (bathrooms.value) total += 1
  if (bedrooms.value) total += 1

  total += facetsFiltersParams.value.length || 0

  return total
})

watch(
  () => countTotalFiltersSelected.value,
  (val) => {
    setFiltersCount(val)
  },
)

const removeSearchInputOption = async (
  removedOption: Partial<InputSearchQuery>,
) => {
  const locations = queryValueMultiple.value.filter(
    (q) => !(q.type === removedOption.type && q.value === removedOption.value),
  )

  setMultipleLocations(locations)
  await refetchResultsFrom('clearQuery')
}

// B2B2C
const selectedPartnerHouse = ref<SearchPartnerHouse | null>(null)
const houseDownloadPdfModal = ref(false)
const openModalDownloadPdf = (house: SearchPartnerHouse) => {
  selectedPartnerHouse.value = house
  houseDownloadPdfModal.value = true
}
const closeModalDownloadPdf = () => {
  selectedPartnerHouse.value = null
  houseDownloadPdfModal.value = false
}
</script>

<template>
  <div class="mb-10 w-full">
    <div ref="searchContainer">
      <div
        v-if="isLoading"
        class="fixed inset-x-0 top-0 z-fixed bg-gray-200/90"
      />

      <SearchTopBarPartner
        :end-date="endDate"
        :capacity="capacity"
        :bedrooms="bedrooms"
        :bathrooms="bathrooms"
        :is-loading="isLoading"
        :max-budget="maxBudget"
        :min-budget="minBudget"
        :query-values="queryValueMultiple"
        :start-date="startDate"
        @clear-filter-numeric="clearFilterNumeric"
        @clear-query="clearQuery"
        @dates-cleared="datesCleared"
        @refine-by-query="refineByQuery"
        @refine-filter-numeric="filteredNumeric"
        @toggle-filters-modal="showFiltersModal = true"
        @trigger-click="triggerClick"
        @update:end-date="handleSetDatesEnd"
        @update:start-date="handleSetDatesStart"
        @update-budget="updateBudget"
        @click-proposal-button="showModalProposalCreate = true"
        @remove-option="removeSearchInputOption"
      />

      <SearchNoResult v-if="!hasResults" @clear-refinements="clearFilters">
        <SearchWaitingListCard
          v-if="destinationId"
          :banner="!$device.isMobile"
          :bathrooms="bathrooms"
          :bedrooms="bedrooms"
          :capacity="capacity"
          :currency="currency"
          :destination-id="Number(destinationId)"
          :end-date="endDate"
          :max-budget="maxBudget"
          :min-budget="minBudget"
          :start-date="startDate"
          class="mt-12"
        />
      </SearchNoResult>

      <div
        :class="[
          'mt-4',
          { flex: !isSmallScreen },
          { 'px-4': isSmallScreen, 'px-6': !isSmallScreen },
        ]"
      >
        <div
          v-if="!isSmallScreen && hasResults"
          class="sticky top-[calc(142px+1rem)] h-full w-3/12 xl:top-[calc(142px+1rem)]"
          data-cy="search-sidebar-filter"
        >
          <SearchFilterSidebar
            class="mr-4"
            :facet-filters="facetFilters"
            :facets="facets"
            :loading-facets="isLoading"
            @changeFacet="refineFacets"
            @clearFacetsOther="clearFacetsOther"
          />
        </div>

        <div
          :class="{
            'w-9/12': !isSmallScreen && hasResults,
            'w-full': !hasResults,
          }"
        >
          <div>
            <BaseRow v-if="hasResults" id="search-results">
              <BaseCol cols="24">
                <SearchResultHeader
                  :current-replica="currentReplica.value"
                  :is-loading="isLoading"
                  :map-visible="mapVisible"
                  :replicas="replicas"
                  :search-results-text="searchResultsText"
                  show-btn-map-and-sort
                  @change-replica="sortByReplica"
                  @toggle-map="toggleMap"
                />

                <template v-if="!mapVisible">
                  <SearchListLoadPreviousButton
                    v-if="showPreviousButton"
                    :page="page"
                    @previous-page="paginate('previous-page')"
                  />

                  <BaseRow v-if="isLoading" class="mb-6">
                    <BaseCol
                      v-for="n in numberPlaceholderProducts"
                      :key="`base-house-card-placeholder-${n}`"
                      class="mb-4"
                      cols="24"
                      md="12"
                      xxl="8"
                    >
                      <BaseHouseCardPlaceholder />
                    </BaseCol>
                  </BaseRow>

                  <BaseRow class="mb-2">
                    <template
                      v-for="house in formattedHits"
                      :key="`house-${house.id}`"
                    >
                      <template
                        v-if="
                          !isLoading &&
                          startDate &&
                          endDate &&
                          invalidHouses.first === house.id
                        "
                      >
                        <h2
                          :key="`text-${house.id}`"
                          :class="[
                            'mb-8 w-24/24 px-4 text-4xl',
                            {
                              'mt-8': hasOnlyInvalidResults,
                              'mt-16': !hasOnlyInvalidResults,
                            },
                          ]"
                        >
                          {{
                            $t(
                              'search.invalidPeriodHouses.otherAvailableHouses',
                            )
                          }}
                        </h2>
                      </template>

                      <BaseCol class="mb-6 md:mb-4" cols="24" md="12" xxl="8">
                        <SearchHouseCard
                          :id="house.id"
                          :bathrooms="house.bathrooms"
                          :bedrooms="house.bedrooms"
                          :capacity="house.capacity"
                          :destination="house.destination"
                          :photos="house.photos"
                          :private-token="house.privateToken"
                          :slug="house.slug"
                          :title="house.name"
                          price=""
                          @handle-click="trackHouse(house.id)"
                        >
                          <template #card-action>
                            <WishlistToggle
                              v-slot="slotProps"
                              :house-id="house.id"
                              :house-name="house.name"
                              @callback-click="setFavoriteHouse(house)"
                            >
                              <HouseWishlistButton
                                :is-active="slotProps.active"
                              />
                            </WishlistToggle>
                          </template>

                          <template #card-content>
                            <div
                              v-if="house.iconicCollection"
                              data-testid="house-card__iconic"
                              :class="`
                                absolute top-3.5 px-4 py-1.5 z-20
                                bg-primary-800 bg-opacity-80
                                text-white text-3xs uppercase tracking-[5px]
                              `"
                            >
                              {{ $t('global.iconic') }}
                            </div>
                            <SearchPartnerHouseDetail
                              class="px-4"
                              :currency="currency"
                              :definitive-prices="house.definitivePrices"
                              :eur-public-price="
                                house.currentPrice.eurPublicPrice
                              "
                              :is-refined-by-date="hasDates"
                              :partner-commission-rate="
                                house.commissionRateB2b2c
                              "
                              :price-value="house.currentPrice.value"
                              :price="house.currentPrice.text"
                            />
                            <div class="p-4 text-center md:text-left">
                              <BaseButton
                                class="text-md"
                                color="secondary"
                                size="xs"
                                type="button"
                                variant="link"
                                @click.prevent="openModalDownloadPdf(house)"
                              >
                                {{ $t('search.seePropertyPdf') }}
                              </BaseButton>
                            </div>
                          </template>
                        </SearchHouseCard>
                      </BaseCol>

                      <ClientOnly>
                        <BaseCol
                          v-if="showHouseCardInfo(house.id)"
                          class="mb-4"
                          cols="24"
                          md="12"
                          xxl="8"
                        >
                          <BaseHouseCardInfo />
                        </BaseCol>
                      </ClientOnly>
                    </template>
                  </BaseRow>

                  <SearchListLoadMoreButton
                    :loading="isLoading"
                    :page="page"
                    :show-paginate="showPaginate"
                    @next-page="paginate('next-page')"
                  />
                </template>

                <LazySearchMap
                  v-else-if="!isLoading"
                  :end-date="endDate"
                  :hits-maps="hitsMap"
                  :start-date="startDate"
                  :track-house="trackHouse"
                  :user-is-admin="false"
                  :user-is-partner="true"
                  class="search__map"
                  @click-toggle-wishlist="setCurrentHouseFavorite"
                />
              </BaseCol>
            </BaseRow>

            <SearchMapButton
              v-if="isSmallScreen"
              :map-visible="mapVisible"
              :show-map-button="showMapButton"
              @toggle-map="toggleMap"
            />
          </div>
        </div>
      </div>
    </div>

    <SearchFilterModalMobile
      v-model="showFiltersModal"
      :bathrooms-quantity="bathrooms"
      :bedrooms-quantity="bedrooms"
      :capacity-quantity="capacity"
      :facet-filters="facetFilters"
      :facets="facets"
      :loading-facets="isLoading"
      :nb-hits="nbHits"
      @change-facet="refineFacets"
      @clear-all="clearAllFilterModal"
      @clear-filter-numeric="clearFilterNumeric"
      @refine-filter-numeric="filteredNumeric"
      @toggle-filters="toggleFiltersModal"
    />

    <SearchAdminHousePdfModal
      v-if="selectedPartnerHouse"
      v-model="houseDownloadPdfModal"
      :bathrooms="selectedPartnerHouse.bathrooms"
      :bedrooms="selectedPartnerHouse.bedrooms"
      :capacity="selectedPartnerHouse.capacity"
      :destination="selectedPartnerHouse.destination"
      :first-photo-url="selectedPartnerHouse.firstPhotoUrl"
      :house-id="selectedPartnerHouse.id"
      :name="selectedPartnerHouse.name"
      :private-token="selectedPartnerHouse.privateToken"
      @close-modal="closeModalDownloadPdf"
    />

    <WishlistMultipleModal v-bind="currentHouseFavoriteSelected" />
  </div>
</template>

<style>
@screen md {
  .search-filter .form-select .multiselect__select:before {
    @apply hidden;
  }
}

.search-filter .form-select--mobile {
  @apply block mb-4 w-full;
}

@screen md {
  .search-filter .form-select--mobile {
    @apply hidden;
  }
}
.search-filter .form-select--mobile .dropdown-toggle {
  @apply w-full py-4 px-3;
  top: -1rem;
}
.search-filter .form-select--mobile .dropdown-menu {
  @apply mt-2;
}
.search-filter .form-select--mobile .search-filter__icon {
  @apply hidden;
}

.search__map.base-map {
  height: 70vh;
}

@screen lg {
  .search__map.base-map {
    height: 850px;
  }
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-300;
}
.fade-enter,
.fade-leave-to {
  @apply opacity-0;
}
</style>
